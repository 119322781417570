import { defineComponent } from 'vue'

import DsView from '@/components/DsView'
import { Model } from '@/components/DsForm'
import { Column } from '@/components/DsTable'
import DsTag from '@/components/DsTag'

const supplieModel: Model = {
  type: 'object',
  properties: {
    supplier: {
      model: 'suppliers',
      label: 'Supplier',
      type: 'uuid',
    },
    name: {
      type: 'string',
      label: 'Name',
      required: true
    },
    component: {
      model: 'components',
      type: 'uuid',
      label: 'Component',
    },
    quantity: {
      type: 'number',
      label: 'Quantity',
    },
    date: {
      type: 'date',
      label: 'Date',
    },
  },
}

const formatter = (value: any, relations: any, relationKey: string, propertieKey: string) => {
  if (value === undefined || !relations[relationKey] || !relations[relationKey][value]) {
    return '-'
  }
  else {
    return <DsTag>{relations[relationKey][value][propertieKey]}</DsTag>
  }
}
const columns: Column[] = [
  {
    key: 'name',
    header: 'Name',
    formatter: (value: any, relations: any) => value || '-'
  },
  {
    key: 'supplier',
    header: 'Supplier',
    formatter: (value: any, relations: any) => formatter(value, relations, 'suppliers', 'name')
  },
  {
    key: 'recipe',
    header: 'Recipe',
    formatter: (value: any, relations: any) => formatter(value, relations, 'recipes', 'identifier')
  },
  {
    key: 'component',
    header: 'Components',
    formatter: (value: any, relations: any) => formatter(value, relations, 'components', 'name')
  },
  {
    key: 'quantity',
    header: 'Quantity',
  },
  {
    key: 'price',
    header: 'Price',
    formatter: (value) => value ? `${value} €` : '-'
  },
  {
    key: 'date',
    header: 'Date',
    formatter: (value) => {
      if (typeof value === 'string') {
        return new Date(value).toISOString().split('T')[0]
      }
      return '-'
    },
  },
]

const defaultOrder = () => ({})

export default defineComponent({
  name: 'Supplies',
  components: { DsView },
  setup() {
    return () => (
      <DsView
        view={{
          collection: 'supplies',
          title: 'Supplies',
          model: supplieModel,
          columns: columns,
          createButtonLabel: 'New supply',
          rowKey: 'id',
        }}
        defaultValue={defaultOrder}
      />
    )
  },
})
